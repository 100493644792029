import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaCheckCircle } from "react-icons/fa";
import { ImBlocked } from "react-icons/im";

import EditIcon from "../../assets/svgs/pencil.inline.svg";

import requests from "../../services/requests";
import Card from "../../components/Cards";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import InputSearch from "../../components/Input/InputSearch";
import Paginate from "../../components/Paginate/Paginate";
import ModalRemove from "./components/ModalRemove";
import ModalReative from "./components/ModalReative";
import ModalEdit from "./components/ModalEdit";
import ModalRegister from "./components/ModalRegister";

const UserManagement = () => {
    const [openModal, setOpenModal] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [openModalRemove, setOpenModalRemove] = useState(false);
    const [openModalReative, setOpenModalReative] = useState(false);
    const [tabModal, setTabModal] = useState("Cadastro de cliente");
    const [tabModalEdit, setTabModalEdit] = useState("Editar perfil");
    const [tabModalRemove, setTabModalRemove] = useState("Remover cliente");
    const [tabModalReative, setTabModalReative] = useState("Reativar cliente");
    const [searchString, setSearchString] = useState("");
    const [list, setList] = useState([]);
    const [currentEntity, setCurrentEntity] = useState(null);
    const [action, setAction] = useState(1);
    const [meta, setMeta] = useState({
        current_page: 1,
    });

    const onChangeSearchString = e => {
        let search = e.target.value.trim();
        setSearchString("");
        if (search.length > 2) {
            setSearchString(search);
        }
    };

    useEffect(() => {
        loadList();
    }, [action, searchString]);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const loadList = async () => {
        requests
            .listClientsManage(
                {
                    search: searchString,
                },
                meta.current_page > 0 ? meta.current_page : 1,
                25
            )
            .then(list => {
                setList(list.data);
                setMeta(list.meta);
            });
        source.cancel("Operation canceled by the user.");
    };

    const actionModal = () => {
        setOpenModal(!openModal);
    };

    const closeModal = () => {
        setOpenModal(false);
    };

    const actionModalEdit = user => {
        setCurrentEntity(user);
        setOpenModalEdit(!openModalEdit);
    };

    const actionModalRemove = user => {
        setCurrentEntity(user);
        setOpenModalRemove(!openModalRemove);
    };

    const actionModalReative = user => {
        setCurrentEntity(user);
        setOpenModalReative(!openModalReative);
    };

    const closeModalEdit = () => {
        setTabModalEdit("Editar perfil");
        setOpenModalEdit(false);
    };

    const closeModalRemove = () => {
        setOpenModalRemove(false);
    };

    const closeModalReative = () => {
        setOpenModalReative(false);
    };

    return (
        <Layout>
            <ModalRegister
                actionModal={closeModal}
                tabModal={tabModal}
                setTabModal={setTabModal}
                openModal={openModal}
                handleSuccess={() => {
                    closeModal();
                    loadList();
                }}
            />
            <ModalEdit
                actionModal={closeModalEdit}
                tabModal={tabModalEdit}
                setTabModal={setTabModalEdit}
                openModal={openModalEdit}
                selectedEntity={currentEntity}
                handleSuccess={() => {
                    closeModalEdit();
                    loadList();
                }}
            />
            <ModalRemove
                actionModal={closeModalRemove}
                tabModal={tabModalRemove}
                setTabModal={setTabModalRemove}
                openModal={openModalRemove}
                entity={currentEntity}
                handleSuccess={() => {
                    closeModalRemove();
                    loadList();
                }}
            />
            <ModalReative
                actionModal={closeModalReative}
                tabModal={tabModalReative}
                setTabModal={setTabModalReative}
                openModal={openModalReative}
                entity={currentEntity}
                handleSuccess={() => {
                    closeModalReative();
                    loadList();
                }}
            />
            <SEO title="Gestão de clientes" />
            <div className="p-4">
                <Card>
                    <div className="flex justify-between border-b border-C2 pb-4">
                        <button
                            className="bg-roxo_oficial text-white px-4 py-1 rounded-md float-right"
                            onClick={actionModal}
                        >
                            Cadastrar cliente
                        </button>
                    </div>
                    <div className="flex justify-between my-4">
                        <h5 className="uppercase text-C6">Clientes</h5>
                        <div>
                            <InputSearch placeholder="buscar" className="h-8 w-56" onChange={onChangeSearchString} />
                        </div>
                    </div>

                    <table className="table-auto w-full">
                        <thead className="border-b border-roxo_oficial">
                            <tr>
                                {/*<th className="text-left w-5">
                    <label>
                      <input
                        type="checkbox"
                        id="allSelect"
                        name="allSelect"
                        value="allSelect"
                      />
                      <span className="check-box-effect"></span>
                    </label>
                  </th>*/}
                                <th className="text-left min-w-5 text-roxo_oficial font-normal">Razão Social</th>
                                <th className="text-left min-w-5 text-roxo_oficial font-normal">Nome Fantasia</th>
                                <th className="text-left max-w-56 text-roxo_oficial font-normal">Documento</th>
                                <th className="text-center max-w-56 text-roxo_oficial font-normal ">Processos</th>
                                <th className="text-center max-w-56 text-roxo_oficial font-normal px-3">Orçamentos</th>
                                <th className="text-center max-w-56 text-roxo_oficial font-normal whitespace-no-wrap">
                                    Power BI
                                </th>
                                <th className="text-center max-w-56 text-roxo_oficial font-normal px-3 whitespace-no-wrap">
                                    Conexos ID
                                </th>
                                <th className="w-20 text-left text-roxo_oficial font-normal">Ações</th>
                            </tr>
                        </thead>
                        <tbody className="pt-2">
                            {list.map((data, key) => {
                                return (
                                    <tr key={data.identifier}>
                                        <td className="text-left font-normal">
                                            {data.name}{" "}
                                            <span className="font-bold">
                                                ({data.type})
                                            </span>
                                        </td>
                                        <td className="text-left font-normal">{data.companyName}</td>
                                        <td className="text-left font-normal whitespace-no-wrap">
                                            {data.documentNumberFormatted}
                                        </td>
                                        <td className="text-center font-normal">
                                            {data.qttProcesses ? data.qttProcesses : "0"}
                                        </td>
                                        <td className="text-center font-normal">{data.qttQuotes ? data.qttQuotes : "0"}</td>
                                        <td className="text-center font-normal">
                                            {data.powerbiUrl && data.powerbiUrl.length > 0 ? "Cadastrado" : "-"}
                                        </td>
                                        <td className="text-center font-normal">{data.conexosId}</td>
                                        <td className="w-20 text-left font-normal">
                                            {data.pendingImport ? (
                                                <>
                                                    <h6 className="p-2 mt-2 mr-2 text-canal_vermelho">Pendente</h6>
                                                </>
                                            ) : data.isActive ? (
                                                <>
                                                    <button
                                                        className="p-2 mt-2 mr-2 rounded-md bg-roxo_oficial_light"
                                                        onClick={() => actionModalEdit(data)}
                                                        title="Editar cliente"
                                                    >
                                                        <EditIcon />
                                                    </button>
                                                    <button
                                                        className="p-2 mt-2 rounded-md bg-canal_vermelho"
                                                        onClick={() => actionModalRemove(data)}
                                                        title="Inativar cliente"
                                                    >
                                                        <ImBlocked color="#fff" />
                                                    </button>
                                                </>
                                            ) : (
                                                <button
                                                    className="p-2 mt-2 rounded-md bg-canal_verde"
                                                    onClick={() => actionModalReative(data)}
                                                    title="Reativar cliente"
                                                >
                                                    <FaCheckCircle color="#fff" />
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {meta.total_pages > 1 && (
                        <Paginate meta={meta} setMeta={setMeta} action={action} setAction={setAction} showDetails={true} />
                    )}
                </Card>
            </div>
        </Layout>
    );
};

export default UserManagement;
