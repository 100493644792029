import React, { useState } from "react";
import Modal from "../../../components/Modals";
import requests from "../../../services/requests";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";

const ModalRemove = ({ actionModal, setTabModal, openModal, handleSuccess, entity }) => {
    const [loading, setLoading] = useState(false);

    return (
        <Modal
            onClose={actionModal}
            size="xs:w-11/12 lg:w-4/12"
            show={openModal}
            title={
                <div className="flex items-center">
                    <h5
                        onClick={() => setTabModal("Remover cliente")}
                        className={`mr-6 cursor-pointer text-roxo_oficial font-bold`}
                    >
                        Inativar {entity?.name}
                    </h5>
                </div>
            }
        >
            <p className="text-center">Tem certeza que deseja realizar esta ação?</p>
            <button
                className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-10"
                onClick={() => {
                    setLoading(true);
                    requests
                        .deleteClient(entity.identifier)
                        .then(() => {
                            setLoading(false);
                            toast.success("Cliente " + entity.name + " pendente na rotina de inativação!");
                            handleSuccess();
                        })
                        .catch(() => {
                            setLoading(false);
                        });
                }}
            >
                Inativar {loading && <ClipLoader size="13" color="white" />}
            </button>
        </Modal>
    );
};
export default ModalRemove;
